import React from "react";
import { toast } from "react-toastify";
import { mutation as createContactMutation } from "services/mutations/createContactMutation";
import i18n from '../i18n/i18n';
import { mutation as createBulkSigningAuditMutation } from "../services/mutations/createBulkSigningAuditMutation";
import { mutation as createBulkSigningMutation } from "../services/mutations/createBulkSigningMutation";
import { mutation as createCsvMutation } from "../services/mutations/createCsvMutation";
import { mutation as csvUploadMutation } from "../services/mutations/csvUploadMutation";
import { mutation as maintainSigneeOrderMutation } from "../services/mutations/maintainSigneeOrderMutation";
import { mutate } from "./relay";
import { RouteEnums } from "./routes";

export interface ProxyObjectType { }
const emptyProxyObject: ProxyObjectType = new Proxy(
    {},
    {}
    // {
    //     get: () => {
    //         return emptyProxyObject;
    //     },
    // }
);
const isEmpty = (val: any) => {
    // Stolen From: https://stackoverflow.com/a/28953167
    /*
    test results
    --------------
    [] true, empty array
    {} true, empty object
    null true
    undefined true
    "" true, empty string
    '' true, empty string
    0 false, number
    true false, boolean
    false false, boolean
    Date false
    function false
    */
    if (val === emptyProxyObject) return true;
    if (val === undefined) return true;
    if (
        typeof val == "function" ||
        typeof val == "number" ||
        typeof val == "boolean" ||
        Object.prototype.toString.call(val) === "[object Date]"
    )
        return false;
    if (val == null || val.length === 0)
        // null or 0 length array
        return true;
    if (typeof val == "object") if (Object.keys(val).length === 0) return true;
    return false;
};
export const getValue = (val: any) => {
    return isEmpty(val) ? "-" : val;
};
const formatName = (name: any) => {
    if (!name) return "User Name";
    const splitName = name.split(" ");
    if (splitName.length === 1) return splitName[0];
    else if (splitName.length > 1)
        return `${splitName[0]} ${splitName[1].charAt(0)}.`;

    return "";
};

const capitalize = (s: string) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
};

const detectMobile = () => {
    const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i,
    ];

    return toMatch.some((toMatchItem) => {
        return window.navigator.userAgent.match(toMatchItem);
    });
};

const BULK_AUDIT_ACTIVITY = {
    CREATED: 1,
    PICKED: 2,
    UPLOADED: 3,
    DOWNLOADED: 4,
    SENT: 5,
};

const showToaster = (eventType: string, eventMess: string) => {
    switch (eventType.toLowerCase()) {
        case "success":
            toast.success(eventMess, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
            });
            break;

        case "warn":
            toast.warn(eventMess, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
            });
            break;

        case "info":
            toast.info(eventMess, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
            });
            break;

        case "error":
            toast.error(eventMess, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
            });
            break;

        default:
            toast(eventMess, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            break;
    }
};

const validatePassword = (input: string) => {
    const pattern =
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&?^~])[A-Za-z\d@$!%*#?&]{8,16}$/;
    if (!pattern.test(String(input))) {
        return "Must Contain 8 to 16 Characters, One Uppercase, One Lowercase, One Number and one special case Character";
    } else {
        return "";
    }
};

const getMsgByBulkAuditActivity = (bulkActivity: number) => {
    switch (bulkActivity) {
        case 1:
            return "The Document has been created";
        case 2:
            return "The Template has been selected";
        case 3:
            return "The Template has been uploaded";
        case 4:
            return "The CSV has been downloaded";
        case 5:
            return "The Template has been sent";

        default:
            return "default MSG";
    }
};

const setUpdateBulkSignAuditActivity = (
    bulkSingingID: string,
    bulkActivity: number
) => {
    mutate({
        mutation: createBulkSigningAuditMutation,
        input: {
            input: {
                bulkSingingId: bulkSingingID,
                activity: bulkActivity,
                message: getMsgByBulkAuditActivity(bulkActivity),
            },
        },
        onSuccess: async (data: any) => {
            if (data.createBulkSigningAudit.success) {
            }
        },
        onFailure: (error: any) => {
            if (error) console.warn(error);
        },
    });
};

const createBulkSigningAndCsv = (
    ID: string,
    history: any,
    templateName: string,
    bulkActivity: number
) => {
    mutate({
        mutation: createBulkSigningMutation,
        input: { input: { id: ID } },
        onSuccess: async (createBulkData: any) => {
            if (createBulkData.createBulkSigning.bulkSigning.id) {
                mutate({
                    mutation: createCsvMutation,
                    input: {
                        input: {
                            id: createBulkData.createBulkSigning.bulkSigning.id,
                        },
                    },
                    onSuccess: async (data: any) => {
                        if (data) {
                            setUpdateBulkSignAuditActivity(
                                createBulkData.createBulkSigning.bulkSigning.id,
                                bulkActivity
                            );
                            history.push(
                                `/${RouteEnums.BULK_SEND}/${createBulkData.createBulkSigning.bulkSigning.id}/${RouteEnums.BULK_FILE_UPLOAD}`,
                                {
                                    csvDownloadURL:
                                        data.createCsv.presignedGetUrl,
                                    csvPostURL:
                                        createBulkData.createBulkSigning
                                            .presignedPostUrl,
                                    fileName: templateName,
                                }
                            );
                        }
                    },
                    onFailure: (error: any) => {
                        if (error) console.warn(error);
                    },
                });
            }
        },
        onFailure: (error: any) => {
            if (error) console.warn(error);
        },
    });
};

const downloadFileByURL = async (fileURL, fileName) => {
    // let pdfURL = `https://signly-documents.s3.ap-south-1.amazonaws.com/doconv/uploads/2/${
    //     props.uploadId.split("@")[1]
    // }-final.pdf`;
    await fetch(fileURL, { headers: { Accept: "text/csv" } })
        .then((response) => response.blob())
        .then((b) => {
            let a = window.document.createElement("a");
            a.href = URL.createObjectURL(b);
            a.setAttribute("download", fileName);
            a.click();
        })
        .catch((e) => {
            console.warn(e);
        });
};

const postToS3 = async (url: string, file: File) => {
    try {
        const response = await fetch(url, {
            method: "PUT",
            body: file,
        });
        if (!response.ok) {
            throw new Error(`Failed to upload file. Status: ${response.status}`);
        }
        return response;
    } catch (error: any) {
        showToaster("error", error);
        throw error;
    }
};

const validateEmail = (value: string) => {
    if (!/^\S+@\S+\.\S+$/.test(value)) {
        return "Enter valid email";
    } else {
        return "";
    }
};

const validateName = (userName: string) => {
    const namePattern = /^[a-zA-Z0-9 ]+$/;
    if (!namePattern.test(String(userName))) {
        return "Enter valid name";
    } else {
        return "";
    }
};

const onCsvFileUploadHandler = async (
    selectedFile: File,
    csvPostURLLink: string,
    bulkSignID: string,
    history: any,
    setLoader: any,
    csvDownloadLink: string
) => {
    setLoader(true);
    if (selectedFile && selectedFile.type.includes("/csv")) {
        try {
            await postToS3(csvPostURLLink, selectedFile);
            mutate({
                mutation: csvUploadMutation,
                input: {
                    input: {
                        id: bulkSignID,
                    },
                },
                onSuccess: async (data: any) => {
                    if (data) {
                        showToaster("success", "CSV is uploaded successfully");
                        setLoader(false);
                        history.push(
                            `/${RouteEnums.BULK_SEND}/${bulkSignID}/${RouteEnums.BULK_CSV_PRE}`,
                            {
                                csvData: data.csvUpload.result.csv_data,
                                errors: data.csvUpload.result.error,
                                csvPostURLLink: csvPostURLLink,
                                csvDownloadLink: csvDownloadLink
                            }
                        );

                    }
                },
                onFailure: (error: any) => {
                    setLoader(false);
                    if (error) {
                        console.warn(error);
                        showToaster("error", error[0]);
                    }
                },
            });
        } catch (error) {
            console.warn(error);
            setLoader(false);
        }
    } else {
        console.warn("upload CSV file only");
        showToaster("error", "Upload CSV file only");
        setLoader(false);
    }
};

const convertDate = (date) => {
    const fullDate = new Date(date);
    const month = fullDate.toLocaleString("en-US", { month: "short" });
    const year = fullDate.getFullYear();
    const day = fullDate.toLocaleString("en-US", { day: "2-digit" });
    return `${day} ${month} ${year}`;
};

const getURLFromPostURL = (URLObjStr: string) => {
    const imageOptions = JSON.parse(URLObjStr.replace(/'/g, '"'));
    return (
        "http://s3-eu-central-1.amazonaws.com/signly-documents/" +
        imageOptions.fields.key
    );
};

const maintainSigneeOrderHandler = (
    checked: any,
    uploadId,
    showHideLoader,
    routeToPrepare?
) => {
    const formData = {
        isMaintainSigneeOrder: checked,
        documentId: uploadId,
    };
    showHideLoader(true);
    mutate({
        mutation: maintainSigneeOrderMutation,
        input: { input: formData },
        onSuccess: async (data: any) => {
            if (data.maintainSigneeOrder.success) {
                showHideLoader(false);
                if (routeToPrepare) routeToPrepare();
            }
        },
        onFailure: (error: any) => {
            showHideLoader(false);
        },
    });
};

const isFileNameValid = (fileName) => {
    const filename = fileName.trim();
    const re1 = /^(?!\d+$)[a-zA-Z0-9 _()-]+$/;
    const re2 = /^(?!\d+$)[a-zA-Z0-9 _()-]+(\.doc|\.docx|\.pdf|\.txt|\.png|\.jpeg|\.jpg)$/;
    return re1.test(filename) || re2.test(filename);
};

const invalidChars = (fileName: string) => {
    const filename = fileName.trim();
    return filename.match(/[^a-zA-Z0-9_ ()-]/g);
}

const DateFormat = {
    1: { display: "DD/MM/YYYY", dp: "dd/MM/yyyy" },
    2: { display: "MM/DD/YYYY", dp: "MM/dd/yyyy" },
    3: { display: "DD MMM YYYY", dp: "dd MMM yyyy" },
    4: { display: "DD-MM-YYYY", dp: "dd-MM-yyyy" },
};

const signeesColor = [
    "#e8dff5",
    "#fce1e4",
    "#fcf4dd",
    "#ddedea",
    "#daeaa6",
    "#ffc09f",
    "#ffee93",
    "#adf7b6",
    "#a0ced9",
    "#fcf5c7",
    "#809bce",
    "#95b8b1",
    "#eac4d5",
    "#daeaf6",
    "#7ec4ff",
    "#d1cfe2",
    "#adf4d6",
    "#ffce93",
    "#84dcc6",
    "#ff686b",
];

const LightenDarkenColor = (col, amt) => {
    var usePound = false;

    if (col[0] === "#") {
        col = col.slice(1);
        usePound = true;
    }

    var num = parseInt(col, 16);

    var r = (num >> 16) + amt;

    if (r > 255) r = 255;
    else if (r < 0) r = 0;

    var b = ((num >> 8) & 0x00ff) + amt;

    if (b > 255) b = 255;
    else if (b < 0) b = 0;

    var g = (num & 0x0000ff) + amt;

    if (g > 255) g = 255;
    else if (g < 0) g = 0;

    return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
};

function addAlpha(color: string, opacity: number): string {
    // coerce values so ti is between 0 and 1.
    const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
    return color + _opacity.toString(16).toUpperCase();
}

const frontendUrl = {
    local: "http://localhost:3000",
    qa: "https://qa.signedly.com/",
    dev: "https://dev.signedly.com",
    apps: "https://apps.signedly.com"
};

const debounce = (cb, d) => {
    let timer;
    return function (...args) {
        if (timer) clearTimeout(timer);
        timer = setTimeout(() => {
            cb(...args);
        }, d);
    };
};

const handleErrors = (response) => {
    if (!response.ok) {
        if (response.status === 404) {
            // file is not uploaded to S3 properly
        }
        showToaster("warn", "Error loading PDF. Please refresh the page")
        throw new Error(response.status);
    }
    return response;
}


const addContact = (name, email) => {
    mutate({
        mutation: createContactMutation,
        input: { input: { name, email } },
        onSuccess: async (data: any) => {
            if (data.createContact.success) {
                console.log("contact added successfully")
            }
        },
        onFailure: (error: any) => {
            console.log("Failed to add contact")
        },
    })
};

const statusMessages = (activity: number, notSingleSignee = true) => {

    if (activity === 1) {
        return i18n.t('activity.docCreatedBy', { ns: 'documentTimeline' });
    } else if (activity === 2) {
        return i18n.t('activity.docSentBy', { ns: 'documentTimeline' });
    } else if (activity === 3) {
        return i18n.t('activity.docViewBy', { ns: 'documentTimeline' });
    } else if (activity === 4) {
        return i18n.t('activity.docSignedBy', { ns: 'documentTimeline' });
    } else if (activity === 5) {
        if (notSingleSignee) {
            return i18n.t('activity.docCompleteBy', { ns: 'documentTimeline' });
        } else {
            return i18n.t('activity.docCompleteAndSigned', { ns: 'documentTimeline' });
        }
    } else if (activity === 6) {
        return i18n.t('activity.docRejectBy', { ns: 'documentTimeline' });
    } else if (activity === 9) {
        return i18n.t('activity.reAssign', { ns: 'documentTimeline' });
    } else if (activity === 10) {
        return i18n.t('activity.docArchiveBy', { ns: 'documentTimeline' });
    } else if (activity === 11) {
        return i18n.t('activity.docUnArchiveBy', { ns: 'documentTimeline' });
    } else if (activity === 13) {
        return i18n.t('activity.docApproved', { ns: 'documentTimeline' })
    } else if (activity === 14) {
        return i18n.t('activity.docExpired', { ns: 'documentTimeline' })
    } else if (activity === 15) {
        return i18n.t('activity.termAccepted', { ns: 'documentTimeline' })
    } else if (activity === 16) {
        return i18n.t('activity.reAssignApprover', { ns: 'documentTimeline' })
    } else if (activity === 17) {
        return i18n.t('activity.opened', { ns: 'documentTimeline' })
    }
    else if (activity === 18) {
        return i18n.t('activity.delivered', { ns: 'documentTimeline' })
    }
    ;
}

const getInitails = (name) => {
    const fullName = name.split(" ");
    let initials: string = "";
    if (fullName[1]) {
        initials = `${fullName[0][0]}${fullName[1][0]}`;
    } else {
        initials = fullName.shift().charAt(0);
    }
    return initials.toUpperCase();
};

export {
    addAlpha, addContact, BULK_AUDIT_ACTIVITY, capitalize, convertDate, createBulkSigningAndCsv, DateFormat, debounce, detectMobile, downloadFileByURL, emptyProxyObject,
    formatName, frontendUrl, getInitails, getMsgByBulkAuditActivity,
    getURLFromPostURL, handleErrors, invalidChars, isEmpty, isFileNameValid, LightenDarkenColor, maintainSigneeOrderHandler, onCsvFileUploadHandler, postToS3, setUpdateBulkSignAuditActivity,
    // GetIPAndCounAPI,
    showToaster, signeesColor, statusMessages, validateEmail,
    validateName, validatePassword
};

export function useChatScroll<T>(dep: T): React.MutableRefObject<HTMLDivElement> {
    const ref = React.useRef<any>();
    React.useEffect(() => {
        if (ref.current) {
            ref.current.scrollTop = ref.current.scrollHeight;
        }
    }, [dep]);
    return ref;
}

export const TEXT_TYPE = {
    TEXT: 1,
    EMAIL: 2,
    NAME: 3,
    NUMBER: 4,
};

export const formatFontSize = (size) => {
    return Number.isInteger(size) ? size : parseFloat(size.toFixed(2));
};

export const CloseWindow = () => {
    try {
        window.open('', '_self', '');
        window.close();
        if (!window.closed) {
            alert('Please close this window manually');
        }
    } catch (e) {
        alert('Please close this window manually');
    }
}

export const isValidName = (name) => {
    if (name.length === 0) {
        return true;
    }
    const regex = /^[A-Za-z\s]+$/;
    if (!name.trim()) {
        return false;
    } else if (!regex.test(name)) {
        return false;
    } else if (name.length < 2) {
        return false;
    } else if (name.length > 30) {
        return false;
    }
    return true;
};

export const isValidEmail = (email) => {
    if (email.length === 0) {
        return true;
    }
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email.trim()) {
        return false;
    } else if (!regex.test(email)) {
        return false;
    }
    return true;
};

export const isValidNumber = (number) => {
    if (number.length === 0) {
        return true;
    }
    const regex = /^[0-9]+$/;
    if (!number.trim()) {
        return false;
    } else if (!regex.test(number)) {
        return false;
    }
    return true;
};

export const VALIDATION_TYPE = {
    DOCUMENT: 1,
    TEMPLATE: 2,
    WORKFLOW: 3,
};