import { graphql } from "babel-plugin-relay/macro";

export const usersQuery = graphql`
    query usersQuery {
        me {
            id
            organization
            organizationName
            email
            firstName
            lastName
            profilePic
            isSocial
            isSuperAdmin
            permissionType
            size
            organizationSuperAdmin
            userPlan
            isStatic
            trialPeriod
            canAccessFreeTrial
            paymentEnabled
            canAccessForm
            customIds
            documentsUsedCurrentMonth
        }
    }
`;
