import CHOICES from "CHOICES";
import { Action, action, Computed, computed, Thunk, thunk } from "easy-peasy";
import { usersQuery } from "services/queries/usersQuery";
import { AUTH_TOKEN } from "utils/constants";
import { fetchQuery, mutate } from "utils/relay";
import { emptyProxyObject, ProxyObjectType } from "utils/utils";

const UK = CHOICES.UserKind;

export interface CurrentUserModel {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    company: string;
    designation: string;
    signature: any;
    isSocial: boolean;
    profilePic: string;
    isSuperAdmin: boolean;
    userPlan: string
}

export interface AuthModel {
    currentUser: CurrentUserModel | ProxyObjectType;
    authToken: string | null;
    isLoggedIn: Computed<AuthModel, boolean>;
    isAdmin: Computed<AuthModel, boolean>;
    setAuthToken: Action<AuthModel, string | null>;
    setCurrentUser: Action<AuthModel, CurrentUserModel | ProxyObjectType>;
    updateAuthToken: Thunk<AuthModel, string>;
    clearAuthToken: Thunk<AuthModel>;
    login: Thunk<AuthModel, any>;
    register: Thunk<AuthModel, any>;
    checkOtp: Thunk<AuthModel, any>;
    resetPass: Thunk<AuthModel, any>;
    forgotPassword: Thunk<AuthModel, any>;
    fetchCurrentUser: Thunk<AuthModel, any>;
}

const AuthStore: AuthModel = {
    currentUser: emptyProxyObject,
    authToken: window.localStorage.getItem(AUTH_TOKEN),

    isLoggedIn: computed(
        (state: any) => state.currentUser !== emptyProxyObject
    ),
    isAdmin: computed((state: any) => state.currentUser.kind === UK.ADMIN),

    setAuthToken: action((state: any, authToken) => {
        state.authToken = authToken;
    }),

    setCurrentUser: action((state: any, currentUser) => {
        state.currentUser = currentUser;
    }),

    updateAuthToken: thunk((actions, authToken) => {
        window.localStorage.setItem(AUTH_TOKEN, authToken);
        actions.setAuthToken(authToken);
    }),

    clearAuthToken: thunk((actions) => {
        window.localStorage.clear();
        actions.setAuthToken(null);
        actions.setCurrentUser(emptyProxyObject);
    }),

    login: thunk((actions, { mutation, input, onSuccess, onFailure }) => {
        mutate({
            mutation,
            input: input,
            onSuccess: function (data: any) {
                onSuccess(data);
            },
            onFailure: function (messages: []) {
                actions.clearAuthToken();
                onFailure(messages);
            },
        });
    }),

    register: thunk((actions, { mutation, input, onSuccess, onFailure }) => {
        mutate({
            mutation,
            input: input,
            onSuccess: function (data: any) {
                onSuccess(data);
            },
            onFailure: function (messages: []) {
                actions.clearAuthToken();
                onFailure(messages);
            },
        });
    }),
    checkOtp: thunk((actions, { mutation, input, onSuccess, onFailure }) => {
        mutate({
            mutation,
            input: input,
            onSuccess: function (data: any) {
                onSuccess(data);
            },
            onFailure: function (messages: []) {
                // actions.clearAuthToken();
                onFailure(messages);
            },
        });
    }),
    resetPass: thunk((actions, { mutation, input, onSuccess, onFailure }) => {
        mutate({
            mutation,
            input: input,
            onSuccess: function (data: any) {
                onSuccess(data);
            },
            onFailure: function (messages: []) {
                onFailure(messages);
            },
        });
    }),
    forgotPassword: thunk(
        (actions, { mutation, input, onSuccess, onFailure }) => {
            mutate({
                mutation,
                input: input,
                onSuccess: function (data: any) {
                    onSuccess(data);
                },
                onFailure: function (messages: []) {
                    actions.clearAuthToken();
                    onFailure(messages);
                },
            });
        }
    ),

    fetchCurrentUser: thunk(async (actions) => {
        try {
            const data: any = await fetchQuery(usersQuery, {}).toPromise();
            if (data.me) {
                actions.setCurrentUser(data.me);
            }
        } catch (error) {
            console.error("Failed to fetch current user:", error);
            actions.clearAuthToken();
        }
    }),
};

export default AuthStore;
