/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type usersQueryVariables = {};
export type usersQueryResponse = {
    readonly me: {
        readonly id: string;
        readonly organization: string | null;
        readonly organizationName: string | null;
        readonly email: string | null;
        readonly firstName: string | null;
        readonly lastName: string | null;
        readonly profilePic: string | null;
        readonly isSocial: boolean | null;
        readonly isSuperAdmin: boolean | null;
        readonly permissionType: ReadonlyArray<number | null> | null;
        readonly size: string | null;
        readonly organizationSuperAdmin: string | null;
        readonly userPlan: string | null;
        readonly isStatic: boolean | null;
        readonly trialPeriod: boolean | null;
        readonly canAccessFreeTrial: boolean | null;
        readonly paymentEnabled: boolean | null;
        readonly canAccessForm: boolean | null;
        readonly customIds: ReadonlyArray<string | null> | null;
        readonly documentsUsedCurrentMonth: number | null;
    } | null;
};
export type usersQuery = {
    readonly response: usersQueryResponse;
    readonly variables: usersQueryVariables;
};



/*
query usersQuery {
  me {
    id
    organization
    organizationName
    email
    firstName
    lastName
    profilePic
    isSocial
    isSuperAdmin
    permissionType
    size
    organizationSuperAdmin
    userPlan
    isStatic
    trialPeriod
    canAccessFreeTrial
    paymentEnabled
    canAccessForm
    customIds
    documentsUsedCurrentMonth
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "MeType",
    "kind": "LinkedField",
    "name": "me",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "organization",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "organizationName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "email",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "firstName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lastName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "profilePic",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isSocial",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isSuperAdmin",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "permissionType",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "size",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "organizationSuperAdmin",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "userPlan",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isStatic",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "trialPeriod",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "canAccessFreeTrial",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "paymentEnabled",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "canAccessForm",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "customIds",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "documentsUsedCurrentMonth",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "usersQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "usersQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "853693b0e9aa5279531175ed9932d5da",
    "id": null,
    "metadata": {},
    "name": "usersQuery",
    "operationKind": "query",
    "text": "query usersQuery {\n  me {\n    id\n    organization\n    organizationName\n    email\n    firstName\n    lastName\n    profilePic\n    isSocial\n    isSuperAdmin\n    permissionType\n    size\n    organizationSuperAdmin\n    userPlan\n    isStatic\n    trialPeriod\n    canAccessFreeTrial\n    paymentEnabled\n    canAccessForm\n    customIds\n    documentsUsedCurrentMonth\n  }\n}\n"
  }
};
})();
(node as any).hash = 'a238da62c4c21c9a462c29e8c74637ed';
export default node;
