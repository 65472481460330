/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type CreateContactInput = {
    email: string;
    name: string;
    clientMutationId?: string | null;
};
export type createContactMutationVariables = {
    input: CreateContactInput;
};
export type createContactMutationResponse = {
    readonly createContact: {
        readonly success: boolean | null;
        readonly contact: {
            readonly id: string;
            readonly name: string | null;
            readonly email: string;
            readonly createdAt: unknown;
            readonly status: boolean | null;
            readonly user: {
                readonly id: string;
                readonly firstName: string | null;
                readonly lastName: string | null;
                readonly profilePic: string | null;
            } | null;
        } | null;
    } | null;
};
export type createContactMutation = {
    readonly response: createContactMutationResponse;
    readonly variables: createContactMutationVariables;
};



/*
mutation createContactMutation(
  $input: CreateContactInput!
) {
  createContact(input: $input) {
    success
    contact {
      id
      name
      email
      createdAt
      status
      user {
        id
        firstName
        lastName
        profilePic
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateContactPayload",
    "kind": "LinkedField",
    "name": "createContact",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ContactType",
        "kind": "LinkedField",
        "name": "contact",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserType",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "firstName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lastName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "profilePic",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "createContactMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "createContactMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "801b14c22ed7b1b3b41af4225749e872",
    "id": null,
    "metadata": {},
    "name": "createContactMutation",
    "operationKind": "mutation",
    "text": "mutation createContactMutation(\n  $input: CreateContactInput!\n) {\n  createContact(input: $input) {\n    success\n    contact {\n      id\n      name\n      email\n      createdAt\n      status\n      user {\n        id\n        firstName\n        lastName\n        profilePic\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '701c96c4aadd78f69b43c40d278c7f24';
export default node;
